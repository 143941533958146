@font-face {
    font-family: 'Louis';
    src: local('Louis'), url(./font/louisregular.otf) format('otf');
}

.centeredDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
}
.mainMobileDiv {
    background: #22506a;
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
}

.mainMobileDiv p {
    margin: 0;
}

.firstRow {
    flex-basis: 42px;
    text-align: center;
    color: white;
    margin-top: 10px;
    font-size: 14px;
}

.firstRow p {
    margin-bottom: 1px;
    letter-spacing: -1px;
    font-weight: bold;
}

.firstRow p:nth-child(2) span{
    font-style: italic;
    letter-spacing: 0px;
    transform: scale(.5, 3);
}

.secondRow {
    flex: 1;
    display: flex;
}

.secondRow > div {
    background: black;
    border: 2px solid white;
    flex: 1;
    margin: 0.2em 0.6em 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.secondRow .triangleContainer {
    background: white;
    padding: 10px;
    border-radius: 50%;
}
.secondRow .triangle {
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 30px solid black;
    transform: translateX(12%);
}

.thirdRow {
    flex-basis: 31px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5em 0.7em 0.6em;
    column-gap: 10px;
}

.thirdRow button {
    white-space: nowrap;
    background: rgb(181,134,44);
    color: white;
    text-transform: capitalize;
    /*width: 111px;*/
    font-size: 18px;
    /*margin-left: 1em;*/
    font-weight: bold;
    border: none;
    cursor: pointer;
}

.thirdRow .firstColumn {
    display: flex;
    flex: 1;
    column-gap: 10px;
}

* {
    font-family: 'Louis';
}